import * as React from "react";
import { ThemeProvider } from "./src/components/theme_context";
import Layout from "./src/components/layout";

const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      {props.location.pathname.startsWith("/404")
        ? <>{element}</>
        : <Layout {...props}>{element}</Layout>
      }
    </ThemeProvider>
  )
};

export default wrapPageElement;