import * as React from "react";

const BogWalkFish = () => {
  return (
    <svg viewBox="0 0 864 864" role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <path style={{ fill: "#000" }} d="m0 257 85 112v126L0 607ZM432 0l313 313v238L432 864 119 495V369ZM779 347l85 85-85 85v-64l21-21-21-21Z"/>
      <path style={{ fill: "#fff" }} d="m173 369 172-203v532L173 495ZM432 64l128 128v480L432 800ZM615 247l100 100v170L615 617Z"/>
    </svg>
  )
};

export default BogWalkFish;