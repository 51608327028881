import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const LeftHamburger = () => {
  const data = useStaticQuery(graphql`
    query TOP_TAGS_QUERY {
      allMdx {
        group(field: { frontmatter: { tags: SELECT } }) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  const tags = data.allMdx.group.sort((a, b) => (a.totalCount > b.totalCount) ? -1 : 1).slice(0, 3);
  const menuRef = React.useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isNestedNavOpen, setIsNestedNavOpen] = React.useState(false);

  React.useEffect(() => {
    const menu = document.getElementById("sideMenu");
    const overlay = document.getElementById("overlay");
    const toggleButton = document.getElementById("hamburger");
    const nestedNavTags = document.getElementById("nestedNavTags");
    const nestedNavHead = document.getElementById("nestedNavHead");
    if (isMenuOpen) {
      menu.classList.add("open");
      overlay.classList.add("open");
      toggleButton.classList.add("open")
    } else {
      menu.classList.remove("open");
      overlay.classList.remove("open");
      toggleButton.classList.remove("open");
      setIsNestedNavOpen(false)
    }
    if (isNestedNavOpen) {
      nestedNavHead.classList.add("open");
      nestedNavTags.style.display = "block"
    } else {
      nestedNavHead.classList.remove("open");
      nestedNavTags.style.display = "none"
    }
  }, [isMenuOpen, isNestedNavOpen]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isMenuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    } 
  });

  const toggleSideMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleNestedNav = () => setIsNestedNavOpen(!isNestedNavOpen);
  
  return (
    <div className="leftHeader">
      <div id="overlay"></div>
      <div ref={menuRef}>
        <button id="hamburger" aria-label="Collapse or expand side menu" onClick={toggleSideMenu} aria-haspopup="true" aria-expanded={isMenuOpen}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        <div id="sideMenu">
          <nav>
            <button id="nestedNavHead" onClick={toggleNestedNav} aria-haspopup="true" aria-expanded={isNestedNavOpen}>bog walk</button>
            <div id="nestedNavTags">
              <Link key="all" to="/blog/" onClick={toggleSideMenu} className="navLink">all posts</Link>
              {tags.map(tag => (
                <Link key={tag.fieldValue} to={`/blog/${tag.fieldValue}/`} onClick={toggleSideMenu} className="navLink">{tag.fieldValue}</Link>
              ))} 
            </div>
            <Link to="/brackish/" onClick={toggleSideMenu} className="navLink">brackish</Link>
          </nav>
        </div>
      </div>
    </div>
  )
};

export default LeftHamburger;
