import * as React from "react";
import { Link } from "gatsby";
import LeftHamburger from "./left_hamburger";
import LogoName from "./logo_name";
import ThemeToggle from "./theme_toggle";
import BogWalkFish from "./bogwalk_fish";
import Footer from "./footer";

const Layout = (props) => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const isHome = props.location.pathname === "/";

  return (
    <div className="pageContainer">
      <LeftHamburger/>
      <div className="centreHeader">
        {!isHome && <LogoName/>}
      </div>
      <div className="rightHeader">
        {!isHome && 
          <Link to="/" aria-label="Go home" title="Go home">
            <BogWalkFish />
          </Link>
        }
        <ThemeToggle/>
      </div>
      <main>
        {props.children}
      </main>
      <Footer/>
    </div> 
  )
};

export default Layout;
