export const THEME_MODE_KEY = 'theme-mode';
export const INITIAL_THEME_MODE_CSS = '--initial-theme-mode';
export const COLOURS = {
  background: {
    dark: 'hsl(0deg, 0%, 13%)',
    light: 'hsl(0deg, 0%, 100%)',
  },
  text: {
    dark: 'hsl(220deg, 19%, 85%)',
    light: 'hsl(230deg, 16%, 11%)',
  },
  accent: {
    dark: 'hsl(130deg, 26%, 61%)',
    light: 'hsl(130deg, 26%, 31%)',
  },
  onAccent: {
    dark: 'hsl(0deg, 0%, 13%)',
    light: 'hsl(0deg, 0%, 100%)',
  },
  title: {
    dark: 'hsl(0deg, 0%, 100%)',
    light: 'hsl(0deg, 0%, 0%)',
  },
  hover: {
    dark: 'hsl(0deg, 0%, 100%)',
    light: 'hsl(130deg, 26%, 31%)',
  },
  inverse: {
    dark: 'hsl(0deg, 0%, 0%)',
    light: 'hsl(0deg, 0%, 100%)',
  },
};
