import React from 'react';
import { THEME_MODE_KEY, INITIAL_THEME_MODE_CSS, COLOURS } from "../styles/style_constants";

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [themeMode, rawSetMode] = React.useState(undefined);
  React.useEffect(() => {
    const root = window.document.documentElement;
    const initialModeValue = root.style.getPropertyValue(INITIAL_THEME_MODE_CSS);
    rawSetMode(initialModeValue);
  }, []);

  const contextValue = React.useMemo(() => {
    function setMode(newValue) {
      const root = window.document.documentElement;
      localStorage.setItem(THEME_MODE_KEY, newValue);
      Object.entries(COLOURS).forEach(([name, colourByMode]) => {
        const cssVarName = `--color-${name}`;

        root.style.setProperty(cssVarName, colourByMode[newValue]);
      });
      rawSetMode(newValue);
    }

    return {
      themeMode,
      setMode,
    };
  }, [themeMode, rawSetMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};