import * as React from "react";
import { Link } from "gatsby";
import BogWalkFish from "./bogwalk_fish";
import { logoName, logo } from "../styles/logo_name.module.css";

const LogoName = () => {
  return (
    <div className={logoName}>
      <Link to="/" aria-label="Go home">
        <span>bog</span>
        <span className={logo}><BogWalkFish /></span>
        <span>walk</span>
      </Link>
    </div>
  )
};

export default LogoName;