import React from 'react';
import { ThemeContext } from './theme_context';

const ThemeToggle = () => {
  const { themeMode, setMode } = React.useContext(ThemeContext);

  if (!themeMode) {
    return null;
  }

  const toggleMode = () => themeMode === "dark" ? setMode("light") : setMode("dark");

  return (
    <button id="modeToggle" onClick={toggleMode} aria-label={`Toggle ${themeMode === "dark" ? "light" : "dark"} mode`}>
      <div id="sun" className={`${themeMode === "dark" ? "show" : ""}`}>
        <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 40 40" aria-hidden="true">
          <path d="M20 28.333q-3.458 0-5.896-2.437-2.437-2.438-2.437-5.896 0-3.458 2.437-5.896 2.438-2.437 5.896-2.437 3.458 0 5.896 2.437 2.437 2.438 2.437 5.896 0 3.458-2.437 5.896-2.438 2.437-5.896 2.437ZM1.667 21.375v-2.75h6.666v2.75Zm30 0v-2.75h6.666v2.75ZM18.625 8.333V1.667h2.75v6.666Zm0 30v-6.666h2.75v6.666Zm-7.833-25.625L6.667 8.583l1.916-1.916 4.125 4.125Zm20.625 20.625-4.125-4.125 1.916-1.916 4.125 4.125Zm-2.209-20.625-1.916-1.916 4.125-4.125 1.916 1.916ZM8.583 33.333l-1.916-1.916 4.125-4.125 1.916 1.916Z"/>
        </svg>
      </div>
      <div id="moon" className={`${themeMode === "light" ? "show" : ""}`}>
        <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 40 40" aria-hidden="true">
          <path d="M20 35q-6.25 0-10.625-4.375T5 20q0-6.25 4.375-10.625T20 5q.417 0 .854.021.438.021 1.021.104-1.583 1.292-2.479 3.229-.896 1.938-.896 4.146 0 3.75 2.625 6.375T27.5 21.5q2.208 0 4.146-.854 1.937-.854 3.229-2.354.083.541.104.937.021.396.021.771 0 6.25-4.375 10.625T20 35Z"/>
        </svg>
      </div>
    </button>
  );
};

export default ThemeToggle;
