import * as React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="socials">
        <a href="https://www.gatsbyjs.com/" aria-label="Gatsby logo" title="Built with Gatsby">
          <svg className="gatsby" viewBox="0 0 24 24" role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 2.571c3.171 0 5.915 1.543 7.629 3.858l-1.286 1.115C16.886 5.572 14.571 4.286 12 4.286c-3.343 0-6.171 2.143-7.286 5.143l9.857 9.857c2.486-.857 4.373-3 4.973-5.572h-4.115V12h6c0 4.457-3.172 8.228-7.372 9.17L2.83 9.944C3.772 5.743 7.543 2.57 12 2.57zm-9.429 9.6l9.344 9.258c-2.4-.086-4.801-.943-6.601-2.743-1.8-1.8-2.743-4.201-2.743-6.515z"/>
          </svg>
        </a>
        <a href="https://github.com/bog-walk" aria-label="Github logo" title="Checkout my GitHub!">
          <svg className="github" viewBox="0 0 24 24" role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
          </svg>
        </a>
        <a href="https://gitlab.com/bog-walk" aria-label="Gitlab logo" title="Stored on GitLab">
          <svg className="gitlab" viewBox="0 0 24 24" role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <path d="m23.523 9.556-.033-.085-3.236-8.457a.843.844 0 0 0-.333-.402.866.868 0 0 0-.99.054.866.868 0 0 0-.287.436L16.46 7.796H7.614L5.43 1.102a.849.85 0 0 0-.287-.438.866.868 0 0 0-.99-.053.85.852 0 0 0-.334.402L.58 9.466l-.033.085a6.008 6.017 0 0 0 1.993 6.954l.01.009.03.021 4.929 3.697 2.438 1.848 1.485 1.123a.999 1 0 0 0 1.208 0l1.485-1.123 2.438-1.848 4.959-3.72.012-.01a6.01 6.02 0 0 0 1.99-6.946z"/>
          </svg>
        </a>
      </div>
      <span className="divider">|</span>
      <span>&copy; 2023 bog-walk</span>
    </footer>
  )
};

export default Footer;
